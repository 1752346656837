import React from "react"
import Layout from "@components/layout/Layout/Layout"
import Demo from "@components/partials/Demo"

const DemoPage = () => (
  <Layout
    title="Demo || WhelpS NFT"
    contentClassName="px-0 max-w-full bg-secondary-whelps"
    page="demo"
  >
    <Demo />
  </Layout>
)
export default DemoPage
