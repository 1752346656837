import React from "react"
import "./demopage.scss"

export default function Demopage() {
    return (
        <div className="demoPage font-poppins">
            <div className="section__1 bg-sky">
                <div className="flex items-center justify-center mx-auto pt-20 sm:pt-24 xl:pt-32 pb-10 sm:pb-16 xl:pb-18 relative">
                    <img src="/demo/dragon__1.png" alt="dragon img" className="absolute left-0 z-10 w-1/4 sm:w-1/3 2xl:w-auto"/>
                    <img src="/demo/laptop.png" alt="latop img" className="z-20 w-3/4 sm:w-1/2 2xl:w-auto"/>
                    <img src="/demo/dragon__2.png" alt="dragon img" className="absolute right-0 z-10 w-1/4 sm:w-1/3 2xl:w-auto"/>
                </div>
            </div>
            <div className="section__2 bg-white pt-16 sm:pt-24">
                <div className="container flex flex-col justify-around lg:flex-row mx-auto">
                    <div>
                        <div className="flex flex-col items-center px-0 lg:px-4">
                            <img src="/demo/icon_user__1.png" alt="user icon" className="h-20 sm:h-28"/>
                            <h2 className="font-poppins-bold pt-2 text-3xl sm:text-4xl uppercase">
                                Single Player
                            </h2>
                            <span className="font-poppins-bold text-xl sm:text-2xl">
                                ( Current Build )
                            </span>
                            <a href="https://drive.google.com/file/d/10rAXwKysrjQFkFq6lEbdjwkwn68lE97S/view?usp=sharing" target="_blank" rel="norefferer" className="bg-blue border-2 border-blue-100 font-poppins-bold keychainify-checked mb-6 mt-3 py-1 rounded-md text-3xl sm:text-4xl text-center text-white uppercase w-full">
                                Download
                            </a>
                        </div>
                        <div className="text-md sm:text-lg line-height-25">
                            <div>
                                1. Download and extract files 
                            </div>
                            <div>
                                2. Run Server/DragonRacingServer_0.1.0.exe
                            </div>
                            <div>
                                3. Run Client/DragonRacingClient_0.1.0.exe
                            </div>
                            <div>
                                4. Do not edit username
                            </div>
                            <div>
                                5. Leave players as default value of "1"
                            </div>
                        </div>
                    </div>
                    <div className="mt-16 lg:mt-0">
                        <div className="flex flex-col items-center px-0 lg:px-4">
                            <img src="/demo/icon_user__2.png" alt="user icon" className="h-20 sm:h-28"/>
                            <h2 className="font-poppins-bold pt-2 text-3xl sm:text-4xl uppercase">
                                Multi Player
                            </h2>
                            <span className="font-poppins-bold text-xl sm:text-2xl">
                                ( Previous Build )
                            </span>
                            <a href="https://drive.google.com/file/d/1wo8LZpFUXBV2tVLYkfjQixxU0RXdNGPf/view?usp=sharing" target="_blank" rel="norefferer" className="bg-blue border-2 border-blue-100 font-poppins-bold keychainify-checked mb-6 mt-3 py-1 rounded-md text-3xl sm:text-4xl text-center text-white uppercase w-full">
                                Download
                            </a>
                        </div>
                        <div className="text-md sm:text-lg line-height-25">
                            <div>
                                1. Download and extract files 
                            </div>
                            <div>
                                2. Run Server/DragonRacing.exe
                            </div>
                            <div>
                                3. You may edit username if you like
                            </div>
                            <div>
                                4. Input correct numbers of friends <br/> 
                                you are playing with as number of players
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section__3 bg-white py-16 sm:py-24 xl:py-32">
                <div className="container flex flex-col sm:flex-row gap-12 sm:gap-4 lg:gap-10 xl:gap-12 justify-between mx-auto">
                    <div>
                        <img src="/demo/slide__1.png" alt="slider img" className="w-full"/>
                    </div>
                    <div>
                        <img src="/demo/slide__2.png" alt="slider img" className="w-full"/>
                    </div>
                    <div>
                        <img src="/demo/slide__3.png" alt="slider img" className="w-full"/>
                    </div>
                </div>
            </div>
        </div>
    )
}